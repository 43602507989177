<template>
    <div>
        <nav-bar title="反馈详情" url="/bug_list"></nav-bar>
        <div class="top">
            <div class="title">
                <div class="title-text">反馈信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-cell title="反馈时间" :value="info.create_time"></van-cell>
                <van-cell title="反馈内容" :value="info.content"></van-cell>
                <van-cell title="反馈状态">
                    <template slot="default">
                        <van-tag type="primary" v-if="info.state==1">已回复</van-tag>
                        <van-tag type="danger" v-if="info.state==0">待回复</van-tag>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>

        <div class="top" v-if="info.state==1">
            <div class="title">
                <div class="title-text">回复信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-cell title="回复时间" :value="info.reply_time"></van-cell>
                <van-cell title="回复人" :value="info.reply_info.examine_name"></van-cell>
                <van-cell title="回复内容" :value="info.reply_content"></van-cell>
            </van-cell-group>
        </div>

        <div class="top" v-if="info.image.length">
            <div class="title">
                <div class="title-text">反馈照片</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <div style="padding: 15px;">
                <van-row>
                    <van-col span="8" v-for="(item,index) in info.image" :key="index">
                        <van-image @click="image_preview(info.image,index)" width="90%" height="60px" fit="cover"
                            :src="item+ '!thumbnail'"></van-image>
                    </van-col>
                </van-row>
            </div>
        </div>


    </div>
</template>

<script>
    import {
        bug_detail
    } from '@/api/bug.js';
    import {
        ImagePreview
    } from 'vant';
    export default {
        name: 'bug_detail',
        data() {
            return {
                info: { image: [], },
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {

            get_data() {
                bug_detail({
                    id: this.$route.query.id
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    }
                });
            },

            //图片预览
            image_preview(img, index) {
                ImagePreview({
                    images: img,
                    startPosition: index ? index : 0
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label {
        font-family: 'FZCS';
    }
</style>