import request from '@/utils/request'
export function bug_submit(data) {
    return request({url: 'bug_submit',data});
}
export function bug_list(data) {
    return request({url: 'bug_list',data});
}
export function bug_detail(data) {
    return request({url: 'bug_detail',data});
}
export function bug_question(data) {
    return request({url: 'bug_question',data});
}